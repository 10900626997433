/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&family=Ubuntu:wght@700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Smartphones (portrait and landscape) ----------- */
/*
P - portrait
L- landscape

devices breakpoints sneak-peak:
https://responsivedesign.is/develop/browser-feature-support/media-queries-for-common-device-breakpoints

technique:
https://css-tricks.com/media-queries-sass-3-2-and-codekit/

usage example:

.class {
	@include breakpoint(phone-l) { width: 60%; }
	@include breakpoint(ipad-three-less) { width: 60%; }
}

*/
.full-width {
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .full-width {
    padding: 0;
  }
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.text-al {
  text-align: left;
}

.text-ar {
  text-align: right;
}

.bold {
  font-weight: 600;
}

.hidden {
  display: none;
}

.desktop {
  display: none;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .desktop {
    display: block;
  }
  .desktop.inline-block {
    display: inline-block;
  }
}

.mobile {
  display: none;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .mobile {
    display: block;
  }
  .mobile.inline-block {
    display: inline-block;
  }
}

.col-xs-5th,
.col-sm-5th,
.col-md-5th,
.col-lg-5th {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.col-xs-5th {
  -ms-flex-preferred-size: 20% !important;
  flex-basis: 20% !important;
  max-width: 20% !important;
}

@media (min-width: 768px) {
  .col-sm-5th {
    -ms-flex-preferred-size: 20% !important;
    flex-basis: 20% !important;
    max-width: 20% !important;
  }
}
@media (min-width: 992px) {
  .col-md-5th {
    -ms-flex-preferred-size: 20% !important;
    flex-basis: 20% !important;
    max-width: 20% !important;
  }
}
@media (min-width: 1200px) {
  .col-lg-5th {
    -ms-flex-preferred-size: 20% !important;
    flex-basis: 20% !important;
    max-width: 20% !important;
  }
}
.article-cover {
  padding-bottom: 96px;
}
.article-cover figure {
  padding: 15px;
  padding-right: 20px;
  box-sizing: border-box;
  position: relative;
}
.article-cover figure img {
  display: block;
  width: 100%;
}
.article-cover figure svg {
  position: absolute;
  z-index: 1;
  display: block;
  width: 16.66%;
  height: auto;
  top: 49%;
  right: 15px;
}
.article-cover figcaption {
  padding-top: 45px;
  padding-bottom: 93px;
  padding-right: 16.66%;
  padding-left: 20px;
  box-sizing: border-box;
  position: relative;
}
.article-cover figcaption svg {
  position: absolute;
  z-index: 1;
  display: block;
  width: 20px;
  height: auto;
  top: 0;
  left: calc(33.34% - 20px);
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.row.reverse {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
}

.col-xs, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-xs {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.col-xs-2 {
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%;
}

.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.col-xs-5 {
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.col-xs-8 {
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.col-xs-11 {
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  margin-left: 8.333%;
}

.col-xs-offset-2 {
  margin-left: 16.667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.333%;
}

.col-xs-offset-5 {
  margin-left: 41.667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.333%;
}

.col-xs-offset-8 {
  margin-left: 66.667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.333%;
}

.col-xs-offset-11 {
  margin-left: 91.667%;
}

.start-xs {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}

.middle-xs {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.bottom-xs {
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.between-xs {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.first-xs {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1;
}

.last-xs {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
  }
  .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .col-sm {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-sm-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }
  .col-sm-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }
  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  .col-sm-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }
  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }
  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  .col-sm-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }
  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-sm-offset-1 {
    margin-left: 8.333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.667%;
  }
  .start-sm {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-sm {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-sm {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-sm {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }
  .middle-sm {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }
  .bottom-sm {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }
  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-sm {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .first-sm {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .last-sm {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}
@media only screen and (min-width: 62em) {
  .container {
    width: 61rem;
  }
  .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .col-md {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-md-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }
  .col-md-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }
  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  .col-md-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }
  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }
  .col-md-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  .col-md-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }
  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-md-offset-1 {
    margin-left: 8.333%;
  }
  .col-md-offset-2 {
    margin-left: 16.667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.333%;
  }
  .col-md-offset-5 {
    margin-left: 41.667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.333%;
  }
  .col-md-offset-8 {
    margin-left: 66.667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.333%;
  }
  .col-md-offset-11 {
    margin-left: 91.667%;
  }
  .start-md {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-md {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-md {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-md {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }
  .middle-md {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }
  .bottom-md {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }
  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-md {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .first-md {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .last-md {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}
@media only screen and (min-width: 75em) {
  .container {
    width: 71rem;
  }
  .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .col-lg {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-lg-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }
  .col-lg-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }
  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }
  .col-lg-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }
  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }
  .col-lg-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }
  .col-lg-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }
  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }
  .col-lg-offset-1 {
    margin-left: 8.333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.667%;
  }
  .start-lg {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }
  .center-lg {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }
  .end-lg {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }
  .top-lg {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }
  .middle-lg {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }
  .bottom-lg {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }
  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .between-lg {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .first-lg {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }
  .last-lg {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}
@keyframes topmenu-slide-down {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: -357px;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes stretch {
  0% {
    width: 20%;
  }
  100% {
    width: 100%;
    left: 0;
  }
}
@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@keyframes shake {
  0%, 100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  50% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@-webkit-keyframes shake-ie {
  0%, 100% {
    margin-top: -20px;
  }
  50% {
    margin-top: 0;
  }
}
@keyframes shake-ie {
  0%, 100% {
    margin-top: -20px;
  }
  50% {
    margin-top: 0;
  }
}
@keyframes slideUp {
  100% {
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes rollOverUp {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 0 -38px;
  }
  51% {
    display: none;
    background-position: 0 38px;
  }
  52% {
    display: block;
    background-position: 0 38px;
  }
  100% {
    background-position: 0 0;
  }
}
.fade-to-black {
  background-color: #000000 !important;
  color: #ffffff !important;
  -webkit-transition: all 0.54s ease-in;
  -moz-transition: all 0.54s ease-in;
  -ms-transition: all 0.54s ease-in;
  -o-transition: all 0.54s ease-in;
  transition: all 0.54s ease-in;
}
.fade-to-black a {
  color: #ffffff !important;
  -webkit-transition: all 0.54s ease-in;
  -moz-transition: all 0.54s ease-in;
  -ms-transition: all 0.54s ease-in;
  -o-transition: all 0.54s ease-in;
  transition: all 0.54s ease-in;
}
.fade-to-black h3,
.fade-to-black ul,
.fade-to-black p {
  background-color: #000000 !important;
  color: #ffffff !important;
  -webkit-transition: all 0.54s ease-in;
  -moz-transition: all 0.54s ease-in;
  -ms-transition: all 0.54s ease-in;
  -o-transition: all 0.54s ease-in;
  transition: all 0.54s ease-in;
}

@keyframes slidein {
  0% {
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.falka-animate {
  display: block;
  position: relative;
  overflow: hidden;
}
.falka-animate svg {
  transform-origin: top right;
  transform: rotate3d(0, 0, 1, 90deg);
  display: block;
}
.falka-animate.rotate svg {
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition: transform 0.75s ease-in-out;
  -moz-transition: transform 0.75s ease-in-out;
  -ms-transition: transform 0.75s ease-in-out;
  -o-transition: transform 0.75s ease-in-out;
  transition: transform 0.75s ease-in-out;
}

.falka-animate-backwards {
  display: block;
  position: relative;
  overflow: hidden;
}
.falka-animate-backwards svg {
  transform-origin: top right;
  transform: rotate3d(0, 0, 1, -90deg);
  display: block;
}
.falka-animate-backwards.rotate svg {
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition: transform 0.75s ease-in-out;
  -moz-transition: transform 0.75s ease-in-out;
  -ms-transition: transform 0.75s ease-in-out;
  -o-transition: transform 0.75s ease-in-out;
  transition: transform 0.75s ease-in-out;
}

.slick-list, .slick-slider, .slick-track {
  position: relative;
  display: block;
}

.slick-loading .slick-slide, .slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list, .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
}

.slick-track:after, .slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
} 
/* usage example:
.class {
	@include breakpoint(phone-l) { width: 60%; }
}
*/
html {
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 24.59px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-weight: 400;
  display: block;
  /* Smartphones (portrait and landscape) ----------- */
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body {
    font-size: 16px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  body {
    font-size: 16px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: #000000;
}

header,
h1, h2, h3, h4, h5, h6 {
  font-family: "Ubuntu", sans-serif;
}

.section-title {
  font-family: "Ubuntu", sans-serif;
  word-spacing: 100vw;
  text-transform: lowercase;
}
.section-title__md {
  font-size: 36px;
  line-height: 32px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .section-title__md {
    font-size: 28px;
    line-height: 26px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .section-title__md {
    font-size: 28px;
    line-height: 26px;
  }
}
.section-title__xl {
  font-size: 72px;
  line-height: 58px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .section-title__xl {
    font-size: 52px;
    line-height: 39px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .section-title__xl {
    font-size: 48px;
    line-height: 36px;
  }
}

.mobile-menu-btn {
  position: absolute;
  z-index: 4;
  display: block;
  top: 24px;
  right: 16px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.mobile-menu-btn svg {
  display: block;
  width: 30px;
  height: auto;
}

.menus-container {
  color: #004F90;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  /* Smartphones (portrait and landscape) ----------- */
}
.menus-container a {
  color: #004F90;
}
@media only screen and (max-width: 768px) {
  .menus-container {
    max-height: 0;
    overflow: hidden;
  }
  .menus-container.expanded {
    max-height: 1000px;
    -webkit-transition: max-height 0.3s ease-in-out;
    -moz-transition: max-height 0.3s ease-in-out;
    -ms-transition: max-height 0.3s ease-in-out;
    -o-transition: max-height 0.3s ease-in-out;
    transition: max-height 0.3s ease-in-out;
  }
}

nav.menu-top {
  position: relative;
  margin: 51px 0 38px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  nav.menu-top {
    margin: 132px 0 0;
  }
}
nav.menu-top .span-link {
  background-color: #ffffff;
  color: #004F90;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}
nav.menu-top > ul > li {
  font-size: 18px;
  /* Smartphones (portrait and landscape) ----------- */
}
nav.menu-top > ul > li > span,
nav.menu-top > ul > li > a {
  display: block;
  padding: 0 0 11px;
  border-bottom: 1px solid #004F90;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  nav.menu-top > ul > li > span,
  nav.menu-top > ul > li > a {
    font-size: 26px;
    margin: 0 0 17px;
  }
}
@media only screen and (min-width: 769px) {
  nav.menu-top > ul > li:last-child > span,
  nav.menu-top > ul > li:last-child > a {
    width: 50%;
  }
}
nav.menu-top > ul > li:hover > a > .span-link {
  background-color: #004F90;
  color: #ffffff;
  -webkit-transition: all 0.16s ease-in-out;
  -moz-transition: all 0.16s ease-in-out;
  -ms-transition: all 0.16s ease-in-out;
  -o-transition: all 0.16s ease-in-out;
  transition: all 0.16s ease-in-out;
}
nav.menu-top > ul > li ul {
  margin-top: 4px;
  /* Smartphones (portrait and landscape) ----------- */
}
nav.menu-top > ul > li ul li {
  font-size: 14px;
  /* Smartphones (portrait and landscape) ----------- */
}
nav.menu-top > ul > li ul li a {
  padding: 4px 0;
  display: block;
}
nav.menu-top > ul > li ul li:hover > a > .span-link {
  background-color: #76b828;
  color: #ffffff;
  -webkit-transition: all 0.16s ease-in-out;
  -moz-transition: all 0.16s ease-in-out;
  -ms-transition: all 0.16s ease-in-out;
  -o-transition: all 0.16s ease-in-out;
  transition: all 0.16s ease-in-out;
}
@media only screen and (max-width: 768px) {
  nav.menu-top > ul > li ul li {
    font-size: 17px;
  }
}
@media only screen and (max-width: 768px) {
  nav.menu-top > ul > li ul {
    margin-bottom: 22px;
  }
}

nav.menu-lang {
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  nav.menu-lang {
    padding: 54px 0;
  }
}
@media only screen and (min-width: 769px) {
  nav.menu-lang {
    padding: 51px 0 38px;
    position: absolute;
    right: 8px;
    top: 0;
  }
}
@media only screen and (min-width: 1490px) {
  nav.menu-lang {
    padding: 51px 0 38px;
    position: absolute;
    right: -35px;
    top: 0;
  }
}
nav.menu-lang ul {
  border: 1px solid #004F90;
  width: 33px;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  nav.menu-lang ul {
    max-height: 30px;
    overflow: hidden;
  }
  nav.menu-lang ul:hover {
    max-height: 100px;
  }
  nav.menu-lang ul:hover li {
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  nav.menu-lang ul {
    width: auto;
    display: inline-block;
  }
}
nav.menu-lang ul li {
  display: none;
  align-items: center;
  place-content: center;
  width: 33px;
  height: 30px;
  background-color: #ffffff;
  /* Smartphones (portrait and landscape) ----------- */
}
nav.menu-lang ul li.default {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  nav.menu-lang ul li.default {
    display: flex;
  }
}
nav.menu-lang ul li a {
  text-transform: lowercase;
  font-size: 14px;
  line-height: 1;
  color: #004F90;
  width: 100%;
  text-align: center;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  nav.menu-lang ul li a {
    font-size: 17px;
    display: block;
    padding-top: 8px;
  }
}
nav.menu-lang ul li:hover {
  background-color: #004F90;
}
nav.menu-lang ul li:hover a {
  color: #ffffff;
  -webkit-transition: all 0.16s ease-in-out;
  -moz-transition: all 0.16s ease-in-out;
  -ms-transition: all 0.16s ease-in-out;
  -o-transition: all 0.16s ease-in-out;
  transition: all 0.16s ease-in-out;
}
@media only screen and (max-width: 768px) {
  nav.menu-lang ul li {
    display: inline-block;
    width: 44px;
    height: 38px;
    border-right: 1px solid #004F90;
  }
  nav.menu-lang ul li:last-child {
    border: none;
  }
}

.desktop-menu-btn {
  display: block;
  width: 33px;
  height: 30px;
  position: fixed;
  top: 60px;
  right: 90px;
  cursor: pointer;
  z-index: 3;
}

h1.logo-strip {
  position: fixed;
  z-index: 3;
  height: 100vh;
  display: flex;
  width: 126px;
  margin-left: 0;
}
@media only screen and (min-width: 1490px) {
  h1.logo-strip {
    width: 140px;
  }
}
h1.logo-strip svg {
  display: block;
  width: 100%;
  height: auto;
}
h1.logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  margin: 24px auto;
  padding: 0;
  text-align: center;
}
h1.logo svg {
  display: inline-flex;
  width: 55%;
  max-width: 302px;
}

.main-header {
  position: relative;
  z-index: 2;
}
.main-header .menu-container {
  display: block;
  background-color: #ffffff;
  padding: 0;
  width: 100%;
  position: fixed;
  margin-top: 0;
  margin-left: 0;
  opacity: 1;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-header .menu-container {
    height: 120px;
    position: relative;
  }
}
.main-header .menu-container .boxed {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .main-header .menu-container .boxed {
    padding: 0;
  }
}
@media only screen and (min-width: 769px) {
  .main-header .menu-container[data-menu-expanded=expanded] {
    margin-top: 0;
    -webkit-transition: all 0.56s ease-out;
    -moz-transition: all 0.56s ease-out;
    -ms-transition: all 0.56s ease-out;
    -o-transition: all 0.56s ease-out;
    transition: all 0.56s ease-out;
  }
  .main-header .menu-container[data-menu-expanded=collapsed] {
    margin-top: -297px;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }
}

.footer {
  color: #004F90;
}
.footer > .row:not(.cover) {
  padding-left: 86px;
  margin-bottom: 0;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .footer > .row:not(.cover) {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1490px) {
  .footer > .row:not(.cover) {
    padding-left: 0;
  }
}
.footer .row {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .footer .row {
    margin-bottom: 80px;
  }
  .footer .row:last-child {
    margin-bottom: 0;
  }
}
.footer .row.title {
  margin-top: 36px;
}
.footer .row.contact-data {
  /* Smartphones (portrait and landscape) ----------- */
}
.footer .row.contact-data p a {
  display: block;
  color: #004F90;
  line-height: 34px;
}
.footer .row.contact-data p a:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 768px) {
  .footer .row.contact-data > .col:not(:empty) {
    margin-bottom: 80px;
  }
  .footer .row.contact-data > .col:not(:empty):last-child {
    margin-bottom: 0;
  }
}
.footer .row.triple {
  margin-top: 24px;
  /* Smartphones (portrait and landscape) ----------- */
}
.footer .row.triple svg {
  display: block;
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 768px) {
  .footer .row.triple {
    margin-bottom: 0;
  }
}
.footer .row.cover {
  margin-top: 0;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .footer .row.cover {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.footer .row.cover img {
  display: block;
  width: 100%;
  height: auto;
}
.footer .row.legal {
  padding: 20px;
  text-align: center;
}
.footer .row.legal .note {
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  padding-left: 116px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .footer .row.legal .note {
    padding-left: 0;
  }
}
.footer .row.legal .docs ul {
  margin: 12px;
}
.footer .row.legal .docs ul li {
  display: inline-block;
  /* Smartphones (portrait and landscape) ----------- */
}
.footer .row.legal .docs ul li a {
  font-size: 12px;
  line-height: 15px;
  margin-right: 12px;
  color: #ffffff;
  display: inline-block;
}
.footer .row.legal .docs ul li a::after {
  display: inline-block;
  content: "|";
  padding-left: 12px;
}
.footer .row.legal .docs ul li a:hover {
  text-decoration: underline;
}
.footer .row.legal .docs ul li:last-child a {
  margin-right: 0;
}
.footer .row.legal .docs ul li:last-child a::after {
  content: "";
  display: none;
}
@media only screen and (max-width: 768px) {
  .footer .row.legal .docs ul li a {
    display: block;
  }
  .footer .row.legal .docs ul li a:after {
    display: none;
  }
}

.page {
  padding-top: 297px;
  position: relative;
  z-index: 1;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page {
    padding-top: 30px;
  }
}
.page > .boxed {
  /* Smartphones (portrait and landscape) ----------- */
}
.boxed {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .boxed {
    padding: 0 16px;
  }
}
.boxed > .row {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}

.section {
  display: block;
  min-height: 400px;
  width: 100%;
}

.row, .col {
  margin: 0;
  padding: 0;
}

.page.home > article {
  min-height: 300px;
}
.page.home #about-us .row.slider {
  position: relative;
}
.page.home #about-us .row.info, .page.home #about-us .row.triple {
  padding-left: 86px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #about-us .row.info, .page.home #about-us .row.triple {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1490px) {
  .page.home #about-us .row.info, .page.home #about-us .row.triple {
    padding-left: 0;
  }
}
.page.home #about-us .row.triple {
  margin-top: 60px;
  margin-bottom: 0;
  /* Smartphones (portrait and landscape) ----------- */
}
.page.home #about-us .row.triple svg {
  display: block;
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 768px) {
  .page.home #about-us .row.triple {
    margin-top: 63px;
    margin-bottom: 0;
  }
}
.page.home #about-us .row.info {
  position: absolute;
  top: 23%;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #about-us .row.info {
    top: 10%;
    position: relative;
    color: #004F90;
  }
}
.page.home #about-us .row.info h2 {
  font-size: 72px;
  line-height: 60px;
  width: 1em;
  text-align: left;
  display: inline-table;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #about-us .row.info h2 {
    width: auto;
    display: block;
    padding: 0 1em;
    font-size: 58px;
    line-height: 48px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 55px;
  }
}
.page.home #about-us .row.info .line {
  margin-top: 59px;
  margin-bottom: 28px;
  border-top-width: 1px;
  border-top-style: solid;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #about-us .row.info .line {
    display: none;
  }
}
@media only screen and (min-width: 769px) {
  .page.home #about-us .row.info .line {
    max-width: 1px;
  }
  .page.home #about-us .row.info .line.stretch {
    animation-name: stretch-info;
    animation-delay: 0.6s;
    animation-duration: 0.86s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  @keyframes stretch-info {
    0% {
      max-width: 1px;
    }
    100% {
      max-width: 846px;
    }
  }
}
.page.home #our-values {
  padding-top: 96px;
  padding-bottom: 96px;
}
.page.home #our-values h2 {
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-values h2 {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 769px) {
  .page.home #our-values h2 {
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
    opacity: 0;
  }
  .page.home #our-values h2.animate {
    animation-name: slidein;
    animation-duration: 0.86s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-delay: 0.26s;
  }
}
.page.home .bg-provider > .row {
  padding-left: 86px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home .bg-provider > .row {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1490px) {
  .page.home .bg-provider > .row {
    padding-left: 0;
  }
}
.page.home #our-team {
  padding-top: 54px;
  padding-bottom: 73px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-team {
    padding-top: 0;
  }
}
.page.home #our-team h2 {
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-team h2 {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 769px) {
  .page.home #our-team h2 {
    -webkit-transform: translateX(-80px);
    transform: translateX(-80px);
    opacity: 0;
  }
  .page.home #our-team h2.animate {
    animation-name: slidein;
    animation-duration: 0.86s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-delay: 0.26s;
  }
}
.page.home #our-team .members {
  margin-top: 110px;
  position: relative;
  margin-right: -8px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-team .members {
    margin-top: 50px;
    margin-right: 0;
  }
}
.page.home #our-team .members .member {
  box-sizing: border-box;
  padding-right: 8px;
  cursor: pointer;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-team .members .member {
    padding-right: 0;
  }
}
.page.home #our-team .members .member > figure h3 {
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 32px;
  background-color: #000000;
}
.page.home #our-team .members .member.clicked {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-team .members .member.clicked {
    min-height: 635px;
  }
}
.page.home #our-team .members .member__description {
  position: absolute;
  text-align: left;
  height: 100%;
  top: 0;
  background-color: rgba(2, 15, 60, 0.95);
  left: 0;
  opacity: 1;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .page.home #our-team .members .member__description {
    display: flex;
    width: calc(20% - 8px);
  }
}
@media only screen and (max-width: 768px) {
  .page.home #our-team .members .member__description {
    left: 0 !important;
  }
}
.page.home #our-team .members .member__description nav {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-team .members .member__description nav {
    top: -26px;
    right: 0;
  }
}
.page.home #our-team .members .member__description nav span {
  cursor: pointer;
}
.page.home #our-team .members .member__description figure,
.page.home #our-team .members .member__description figcaption {
  display: inline-block;
  opacity: 0;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-team .members .member__description figure,
  .page.home #our-team .members .member__description figcaption {
    display: block;
    text-align: center;
  }
}
.page.home #our-team .members .member__description figure {
  flex-basis: 20%;
  max-width: 20%;
  padding-right: 40px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-team .members .member__description figure {
    flex-basis: auto;
    max-width: none;
    margin: 0 auto;
    padding-right: 0;
    padding-top: 0;
  }
}
.page.home #our-team .members .member__description figcaption {
  flex-basis: 80%;
  max-width: 80%;
  box-sizing: border-box;
  padding: 20px 46px 16px 6px;
  /* Smartphones (portrait and landscape) ----------- */
}
.page.home #our-team .members .member__description figcaption h3 {
  line-height: 1;
  padding-bottom: 12px;
  display: inline-block;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 9px;
}
@media only screen and (max-width: 768px) {
  .page.home #our-team .members .member__description figcaption {
    max-width: none;
    padding: 46px;
  }
}
.page.home #our-team .members .member__description__title {
  font-size: 12px;
  margin-bottom: 12px;
}
.page.home #our-team .members .member__description__text-short:after {
  content: "";
}
.page.home #our-team .members .member__description__text-short__only:after {
  display: none;
}
.page.home #our-team .members .member__description__more {
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  font-size: 12px;
  line-height: 16.39px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  margin-top: 16px;
  margin-top: 11px;
  display: inline-block;
  /* Smartphones (portrait and landscape) ----------- */
}
.page.home #our-team .members .member__description__more:hover {
  background-color: #76b828;
  color: #000000 !important;
  -webkit-transition: all 0.16s ease-in-out;
  -moz-transition: all 0.16s ease-in-out;
  -ms-transition: all 0.16s ease-in-out;
  -o-transition: all 0.16s ease-in-out;
  transition: all 0.16s ease-in-out;
}
@media only screen and (max-width: 768px) {
  .page.home #our-team .members .member__description__more {
    font-size: 16px;
    line-height: 20px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 46px;
  }
}
.page.home #our-team .members .member__description.on-hover {
  opacity: 0;
  animation: fadein 0.26s ease-out forwards;
}
.page.home #our-team .members .member__description.on-hover i {
  position: absolute;
  top: 46%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  display: block;
}
.page.home #our-team .members .member__description.on-hover i svg {
  display: inline-block;
  width: 36px;
  height: 34px;
}
.page.home #our-team .members .member__description.on-hover h3 {
  position: absolute;
  display: block;
  width: 100%;
  bottom: 5px;
  left: 0;
  text-align: center;
  font-size: 16px;
  -webkit-transition: font-size 0.2s linear;
  -moz-transition: font-size 0.2s linear;
  -ms-transition: font-size 0.2s linear;
  -o-transition: font-size 0.2s linear;
  transition: font-size 0.2s linear;
}
.page.home #our-team figure img {
  display: block;
  width: 100%;
  height: auto;
}
.page.home #news,
.page.home #investor-relations {
  margin-top: 36px;
}
.page.home #news > .row,
.page.home #investor-relations > .row {
  padding-top: 96px;
  padding-left: 86px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #news > .row,
  .page.home #investor-relations > .row {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1490px) {
  .page.home #news > .row,
  .page.home #investor-relations > .row {
    padding-left: 0;
  }
}
.page.home #news > .row.lifted,
.page.home #investor-relations > .row.lifted {
  padding-top: 0;
}
.page.home #news .line,
.page.home #investor-relations .line {
  border-top: 1px solid #ffffff;
  margin-bottom: 26px;
  margin-top: 27px;
  max-width: 1px;
}
.page.home #news .line.stretch,
.page.home #investor-relations .line.stretch {
  animation-name: stretch-news;
  animation-delay: 0.46s;
  animation-duration: 0.66s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}
@keyframes stretch-news {
  0% {
    max-width: 1px;
  }
  100% {
    max-width: 1210px;
  }
}
.page.home #news .header,
.page.home #investor-relations .header {
  margin-bottom: 29px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #news .header,
  .page.home #investor-relations .header {
    margin-bottom: 0;
  }
}
.page.home #news .header .title select,
.page.home #investor-relations .header .title select {
  display: block;
  margin: 24px auto 24px 0;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #news .header .title select,
  .page.home #investor-relations .header .title select {
    margin: 0 auto 24px;
  }
}
.page.home #news .header .title h2,
.page.home #investor-relations .header .title h2 {
  margin-bottom: 30px;
  display: inline;
  margin-right: 24px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #news .header .title h2,
  .page.home #investor-relations .header .title h2 {
    display: block;
    margin-right: 0;
  }
}
.page.home #news .header .news-item,
.page.home #investor-relations .header .news-item {
  padding-top: 42px;
  padding-bottom: 34px;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
}
.page.home #news .header .news-item:first-child,
.page.home #investor-relations .header .news-item:first-child {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .page.home #news .header .news-item:first-child,
  .page.home #investor-relations .header .news-item:first-child {
    padding-top: 16px;
  }
}
.page.home #news .header .news-item > *,
.page.home #investor-relations .header .news-item > * {
  opacity: 0.8;
}
.page.home #news .header .news-item.active,
.page.home #investor-relations .header .news-item.active {
  /* Smartphones (portrait and landscape) ----------- */
}
.page.home #news .header .news-item.active > *,
.page.home #investor-relations .header .news-item.active > * {
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  .page.home #news .header .news-item.active,
  .page.home #investor-relations .header .news-item.active {
    background-color: #76b828;
  }
}
.page.home #news .header .news-item .item-date,
.page.home #investor-relations .header .news-item .item-date {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #news .header .news-item .item-date,
  .page.home #investor-relations .header .news-item .item-date {
    margin-bottom: 12px;
    font-size: 15px;
  }
}
.page.home #news .header .news-item .item-title:hover, .page.home #news .header .news-item .item-title.active,
.page.home #investor-relations .header .news-item .item-title:hover,
.page.home #investor-relations .header .news-item .item-title.active {
  text-decoration: underline;
}
.page.home #news .header .news-item .file,
.page.home #investor-relations .header .news-item .file {
  text-align: right;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #news .header .news-item .file,
  .page.home #investor-relations .header .news-item .file {
    padding-top: 14px;
    text-align: center;
    margin-bottom: -5px;
  }
}
.page.home #news .article,
.page.home #investor-relations .article {
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #news .article,
  .page.home #investor-relations .article {
    background-color: #ffffff !important;
    color: #004F90 !important;
  }
}
.page.home #news .article__lifted .triple,
.page.home #investor-relations .article__lifted .triple {
  margin-bottom: 0;
  margin-top: 63px;
}
.page.home #news .article__lifted .triple svg,
.page.home #investor-relations .article__lifted .triple svg {
  display: block;
  width: 100%;
  height: auto;
}
@media only screen and (min-width: 769px) {
  .page.home #news .article__lifted,
  .page.home #investor-relations .article__lifted {
    max-height: none;
    padding-right: 30px;
    margin-top: 40px;
  }
  .page.home #news .article__lifted .triple,
  .page.home #investor-relations .article__lifted .triple {
    margin-top: 0;
  }
  .page.home #news .article__lifted .triple svg,
  .page.home #investor-relations .article__lifted .triple svg {
    display: block;
    width: 100%;
    height: auto;
  }
  .page.home #news .article__lifted .article-cover,
  .page.home #investor-relations .article__lifted .article-cover {
    margin-top: -25em;
    min-height: 480px;
  }
}
.page.home #news .article .article-text,
.page.home #investor-relations .article .article-text {
  margin-bottom: 96px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #news .article .article-text,
  .page.home #investor-relations .article .article-text {
    margin-bottom: 0;
  }
}
.page.home #news .article .article-text__date,
.page.home #investor-relations .article .article-text__date {
  display: block;
  margin-top: 21px;
  margin-bottom: 14px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #news .article .article-text__date,
  .page.home #investor-relations .article .article-text__date {
    font-size: 15px;
    margin-top: 84px;
  }
}
.page.home #news .article .article-text h3,
.page.home #investor-relations .article .article-text h3 {
  padding-bottom: 14px;
  border-bottom: 1px solid #ffffff;
  margin-right: 36px;
  box-sizing: border-box;
}
.page.home #news .article .article-text__content,
.page.home #investor-relations .article .article-text__content {
  margin-right: 15px;
  padding-top: 13px;
  height: 9.5em;
  overflow: hidden;
  padding-right: 16.66%;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #news .article .article-text__content,
  .page.home #investor-relations .article .article-text__content {
    padding: 26px 1em 0;
  }
}
.page.home #news .article .article-text__more,
.page.home #investor-relations .article .article-text__more {
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  font-size: 12px;
  line-height: 16.39px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  margin-top: 16px;
  margin-top: 24px;
  display: inline-block;
  /* Smartphones (portrait and landscape) ----------- */
}
.page.home #news .article .article-text__more:hover,
.page.home #investor-relations .article .article-text__more:hover {
  background-color: #76b828;
  color: #000000 !important;
  -webkit-transition: all 0.16s ease-in-out;
  -moz-transition: all 0.16s ease-in-out;
  -ms-transition: all 0.16s ease-in-out;
  -o-transition: all 0.16s ease-in-out;
  transition: all 0.16s ease-in-out;
}
@media only screen and (max-width: 768px) {
  .page.home #news .article .article-text__more,
  .page.home #investor-relations .article .article-text__more {
    font-size: 16px;
    line-height: 20px;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 46px;
    color: #004F90 !important;
    border-color: #004F90 !important;
  }
}
.page.home #news .article .article-text__file,
.page.home #investor-relations .article .article-text__file {
  display: block;
  text-align: center;
  margin-top: 24px;
  /* Smartphones (portrait and landscape) ----------- */
}
.page.home #news .article .article-text__file a,
.page.home #investor-relations .article .article-text__file a {
  display: inline-block;
}
@media only screen and (min-width: 769px) {
  .page.home #news .article .article-text__file,
  .page.home #investor-relations .article .article-text__file {
    text-align: left;
  }
}
.page.home #news .article .article-text__list__file,
.page.home #investor-relations .article .article-text__list__file {
  display: inline-block;
  text-align: center;
  margin-top: 24px;
  margin-right: 12px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .page.home #news .article .article-text__list__file,
  .page.home #investor-relations .article .article-text__list__file {
    text-align: left;
  }
}
.page.home #news .article .article-text__list__file a,
.page.home #investor-relations .article .article-text__list__file a {
  color: #ffffff;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #news .article .article-text__list__file a,
  .page.home #investor-relations .article .article-text__list__file a {
    color: #004F90;
  }
}
.page.home #news select,
.page.home #investor-relations select {
  cursor: pointer;
}
.page.home #investor-relations {
  margin-bottom: 40px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #investor-relations {
    margin-bottom: 80px;
  }
  .page.home #investor-relations .article .article-text h3 {
    margin-right: 0;
  }
  .page.home #investor-relations .article .article-text__date {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
.page.home #what-we-do {
  min-height: 500px;
  margin-top: 72px;
}
.page.home #what-we-do > .row {
  padding-left: 86px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #what-we-do > .row {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1490px) {
  .page.home #what-we-do > .row {
    padding-left: 0;
  }
}
.page.home #what-we-do .box {
  padding-top: 59px;
}
.page.home #what-we-do .box h2 {
  line-height: 74px;
  width: 1em;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #what-we-do .box h2 {
    width: 100%;
    text-align: center;
    word-spacing: inherit;
    padding-left: 1em;
    padding-right: 1em;
    box-sizing: border-box;
  }
}
.page.home #what-we-do .box .line {
  border-top-width: 1px;
  border-top-style: solid;
  margin-bottom: 20px;
  margin-top: 19px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (min-width: 769px) {
  .page.home #what-we-do .box .line:not(.line-offer) {
    max-width: 1px;
  }
  .page.home #what-we-do .box .line:not(.line-offer).stretch {
    animation-name: stretch-wwd;
    animation-delay: 0.36s;
    animation-duration: 0.66s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  @keyframes stretch-wwd {
    0% {
      max-width: 1px;
    }
    100% {
      max-width: 1210px;
    }
  }
}
.page.home #what-we-do .box .title {
  text-align: right;
}
.page.home #what-we-do .box .offer {
  padding: 90px 0;
}
.page.home #what-we-do .box .offer .offer-item {
  text-transform: lowercase;
  /* Smartphones (portrait and landscape) ----------- */
}
.page.home #what-we-do .box .offer .offer-item__title {
  display: block;
  padding: 0 62px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #what-we-do .box .offer .offer-item__title {
    padding: 0;
    text-transform: capitalize;
  }
}
@media only screen and (max-width: 768px) {
  .page.home #what-we-do .box .offer .offer-item {
    margin-bottom: 65px;
  }
}
.page.home #what-we-do .box .offer .offer-item .description {
  padding-top: 1em;
  text-transform: none;
  /* Smartphones (portrait and landscape) ----------- */
}
.page.home #what-we-do .box .offer .offer-item .description span {
  display: block;
}
.page.home #what-we-do .box .offer .offer-item .description span [role=button] {
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  font-size: 12px;
  line-height: 16.39px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  margin-top: 16px;
}
.page.home #what-we-do .box .offer .offer-item .description span [role=button]:hover {
  background-color: #76b828;
  color: #000000 !important;
  -webkit-transition: all 0.16s ease-in-out;
  -moz-transition: all 0.16s ease-in-out;
  -ms-transition: all 0.16s ease-in-out;
  -o-transition: all 0.16s ease-in-out;
  transition: all 0.16s ease-in-out;
}
@media only screen and (max-width: 768px) {
  .page.home #what-we-do .box .offer .offer-item .description > span {
    display: block;
    padding: 20px;
  }
}
.page.home #what-we-do .box .offer .offer-item .line {
  margin-left: 0;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #what-we-do .box .offer .offer-item .line {
    margin-left: 16.667%;
  }
}
.page.home #what-we-do .box .offer .offer-item:last-child {
  margin-bottom: 0;
}
.page.home #what-we-do .box .offer .offer-item:last-child .line {
  display: none;
}
.page.home #our-partners {
  margin-top: 72px;
  padding-bottom: 36px;
}
.page.home #our-partners .incoming {
  padding-left: 86px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-partners .incoming {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1490px) {
  .page.home #our-partners .incoming {
    padding-left: 0;
  }
}
.page.home #our-partners .incoming p svg {
  display: inline-block;
  width: 20px;
  height: auto;
  transform: rotate(180deg);
  margin-top: -56px;
}
.page.home #our-partners .incoming h2 {
  line-height: 32px;
  border: none;
  margin-top: 0;
  margin-bottom: 68px;
}
.page.home #our-partners .incoming.bounce {
  animation-name: shake;
  animation-duration: 1.66s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.page.home #our-partners > .row > .col > .row > .col:first-child {
  padding-left: 86px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-partners > .row > .col > .row > .col:first-child {
    padding-left: 0;
  }
}
@media only screen and (min-width: 1490px) {
  .page.home #our-partners > .row > .col > .row > .col:first-child {
    padding-left: 0;
  }
}
.page.home #our-partners .title-line {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border-top: 1px solid #ffffff;
  margin-top: 61px;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-partners .title-line {
    display: none;
  }
}
@media only screen and (min-width: 769px) {
  .page.home #our-partners .title-line {
    max-width: 1px;
  }
}
@media only screen and (min-width: 769px) {
  .page.home #our-partners .title-line.stretch {
    animation-name: stretch-op;
    animation-delay: 0.26s;
    animation-duration: 0.46s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  @keyframes stretch-op {
    0% {
      max-width: 1px;
    }
    100% {
      max-width: 295px;
    }
  }
}
.page.home #our-partners h2 {
  line-height: 72px;
  border: none;
  display: inline-block;
  margin: 0 0 61px;
  width: min-content;
  padding-top: 14px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-partners h2 {
    display: block;
    width: 100%;
    border: none;
    margin-top: 38px;
  }
}
.page.home #our-partners .partners-item {
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-partners .partners-item {
    flex-direction: column-reverse;
  }
}
.page.home #our-partners .partners-item__text {
  padding-bottom: 65px;
  padding-left: 16.66%;
}
.page.home #our-partners .partners-item__text h3 {
  line-height: 32px;
  padding-bottom: 31px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ffffff;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-partners .partners-item__text h3 {
    padding-top: 30px;
    margin-bottom: 66px;
  }
}
.page.home #our-partners .partners-item__text > span {
  display: block;
  padding: 0 16.66%;
}
.page.home #our-partners .partners-item__img {
  text-align: center;
}
.page.home #our-partners .partners-item__img h3 {
  line-height: 32px;
  padding-bottom: 31px;
  margin-bottom: 30px;
  border-bottom: 1px solid #004F90;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-partners .partners-item__img h3 {
    padding-top: 0;
    margin-bottom: 0;
    border: none;
  }
}
.page.home #our-partners .partners-item__img img {
  margin-top: 16px;
  display: inline-block;
  width: 33.33%;
  max-width: 225px;
  height: auto;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  .page.home #our-partners .partners-item__img img {
    display: block;
    width: 75%;
    margin: 0 auto;
    padding-bottom: 92px;
  }
}

a.home {
  position: fixed;
  z-index: 2;
  bottom: 70px;
  left: 0;
  border: 1px solid #004F90;
  color: #ffffff;
  border-left: none;
  padding: 0 20px;
  line-height: 30px;
  background-color: #76b828;
  text-transform: lowercase;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  a.home {
    top: 121px;
    bottom: auto;
  }
}

#post > .row {
  padding-left: 86px;
  /* Smartphones (portrait and landscape) ----------- */
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  #post > .row {
    padding-left: 0;
    padding-top: 0;
  }
}
@media only screen and (min-width: 769px) {
  #post > .row {
    padding-top: 40px;
  }
}
@media only screen and (min-width: 1490px) {
  #post > .row {
    padding-left: 0;
    padding-top: 40px;
  }
}
#post .line {
  border-top: 1px solid #ffffff;
  margin-top: 123px;
  margin-bottom: 26px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  #post .line {
    margin-top: 60px;
    border: none;
  }
}
#post .article-text {
  margin-bottom: 96px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  #post .article-text {
    margin-bottom: 0;
  }
}
#post .article-text__date {
  display: block;
  margin-top: 21px;
  margin-bottom: 14px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  #post .article-text__date {
    font-size: 15px;
    margin-top: 84px;
    text-align: center;
  }
}
#post .article-text__title {
  font-size: 23px;
  padding-bottom: 14px;
  border-bottom: 1px solid #ffffff;
  margin-right: 0;
  box-sizing: border-box;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  #post .article-text__title {
    text-align: center;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}
#post .article-text__content {
  margin-right: 15px;
  padding-top: 20px;
  padding-bottom: 472px;
  /* Smartphones (portrait and landscape) ----------- */
}
#post .article-text__content.no-img {
  padding-bottom: 172px;
}
@media only screen and (max-width: 768px) {
  #post .article-text__content {
    padding: 26px 1em 72px;
    text-align: center;
  }
}
#post .article-text__content h1, #post .article-text__content h2, #post .article-text__content h3, #post .article-text__content h4, #post .article-text__content h5, #post .article-text__content h6 {
  line-height: 1;
  margin-bottom: 12px;
}
#post .article-text__content h1, #post .article-text__content h2 {
  font-size: 72px;
}
#post .article-text__content h3 {
  font-size: 36px;
}
#post .article-text__content h4 {
  font-size: 21px;
}
#post .article-text__content h5 {
  font-size: 19px;
}
#post .article-text__content ol, #post .article-text__content ul {
  list-style-position: inside;
}
#post .article-text__content ul {
  list-style-type: disc;
}
#post .article-text__content ol {
  list-style-type: decimal;
}
#post .article-text__content blockquote, #post .article-text__content q {
  quotes: initial;
}
#post .article-text__content em {
  font-style: italic;
}
#post .article-text__content strong {
  font-weight: bold;
}
#post .article-text__content p {
  margin-bottom: 12px;
}
#post .article-text__content a:hover {
  text-decoration: underline;
}
#post .article-text__more {
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  font-size: 12px;
  line-height: 16.39px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  margin-top: 16px;
  margin-top: 24px;
  display: inline-block;
}
#post .article-text__more:hover {
  background-color: #76b828;
  color: #000000 !important;
  -webkit-transition: all 0.16s ease-in-out;
  -moz-transition: all 0.16s ease-in-out;
  -ms-transition: all 0.16s ease-in-out;
  -o-transition: all 0.16s ease-in-out;
  transition: all 0.16s ease-in-out;
}
#post .article-text__file {
  display: flex;
  text-align: left;
  margin-top: 24px;
  /* Smartphones (portrait and landscape) ----------- */
}
#post .article-text__file a {
  display: inline-block;
  margin-left: 20px;
}
@media only screen and (max-width: 768px) {
  #post .article-text__file {
    text-align: center;
  }
}
#post .article-cover {
  margin-top: -347px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  #post .article-cover {
    margin-top: -307px;
  }
}
#post.post-bio .line {
  display: none;
}
#post.post-bio .title {
  padding-right: 26px;
  /* Smartphones (portrait and landscape) ----------- */
}
#post.post-bio .title figure {
  padding: 0;
  margin: 0;
}
#post.post-bio .title figure img {
  width: 100%;
  display: block;
  height: auto;
}
@media only screen and (max-width: 768px) {
  #post.post-bio .title {
    padding-right: 0;
    margin-bottom: 24px;
  }
}
#post.post-bio h2 {
  font-size: 21px;
  margin-bottom: 6px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  #post.post-bio h2 {
    text-align: center;
  }
}
#post.post-bio .article-text__title {
  font-size: 14px;
}
#post.post-bio .article-text__content {
  padding-bottom: 172px;
  /* Smartphones (portrait and landscape) ----------- */
}
@media only screen and (max-width: 768px) {
  #post.post-bio .article-text__content {
    text-align: center;
  }
}

.overlay-loader {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 10000;
  color: #000000;
}
.overlay-loader .el {
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.overlay-loader .el svg {
  display: block;
  float: left;
  width: 32px;
  height: 40px;
  transform: rotate(-90deg);
}

.slider-container {
  position: relative;
}
.slider-container .slick-slider img.banner {
  display: block;
  width: 100%;
  height: auto;
}